

* {
    
    transition: all 0.5s ease;
}

body {
    background: #dfdfdf;
}

.container {
    margin-top: 4rem;
    height: 100%;
    padding: 0 16px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
}

form {
    width: 400px;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
}

form div,
form label,
form input,
form textarea {
    width: 100%;
}

.field:nth-of-type(2) {
    margin: 16px 0;
}

label,
input,
textarea {
    padding: 8px;
}

label,
[placeholder] {
    color: #ffffff;
}

label i {
    margin: 0 10px 0 0;
}

.field:focus-within label {
    color: #ffffff;
    letter-spacing: 2px;
}

input,
textarea {
    background: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 4px;
    box-shadow: 0 8px 6px -6px #555;
}

input:focus,
textarea:focus {
    background: white;
    box-shadow: none;
}

textarea {
    resize: none;
}

textarea::-webkit-scrollbar {
    width: 0;
}

#contactWithMe button {
    background: #2f4ad0;
    margin: 16px 0 50px 0;
    padding: 8px 16px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 8px 6px -6px #555;
}

button:hover {
    letter-spacing: 2px;
    box-shadow: none;
}

.social-media {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}


.social-media span {
    font-size: 18px;
}

.social-media span .fas {
    margin: 0 0 0 10px;
}

.social-media a {
    color: #000;
    font-size: 20px;
}

@media (max-width: 425px) {
    form {
        width: 100%;
    }
}

@media (min-width: 1000px) {
    #timeline .demo-card:nth-child(even) .head::after, #timeline .demo-card:nth-child(odd) .head::after {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
  
    #timeline .demo-card:nth-child(even) .head::before, #timeline .demo-card:nth-child(odd) .head::before {
      position: absolute;
      content: "";
      width: 9px;
      height: 9px;
      background-color: #bdbdbd;
      border-radius: 9px;
      box-shadow: 0px 0px 2px 8px #f7f7f7;
    }
  }
  /* Some Cool Stuff */
  .demo-card:nth-child(1) {
    order: 1;
  }
  
  .demo-card:nth-child(2) {
    order: 4;
  }
  
  .demo-card:nth-child(3) {
    order: 2;
  }
  
  .demo-card:nth-child(4) {
    order: 5;
  }
  
  .demo-card:nth-child(5) {
    order: 3;
  }
  
  .demo-card:nth-child(6) {
    order: 6;
  }


  
  

  #timeline h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: 200;
    margin-bottom: 20px;
  }
  #timeline p.leader {
    text-align: justify;
    max-width: 90%;
    margin: auto;
    margin-bottom: 45px;
  }
  #timeline .demo-card-wrapper {
    position: relative;
    margin: auto;
  }
  @media (min-width: 1000px) {
    #timeline .demo-card-wrapper {
      display: flex;
      flex-flow: column wrap;
      width: 1170px;
      height: 1540px;
      margin: 0 auto;
    }
  }
  #timeline .demo-card-wrapper::after {
    z-index: 1;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    border-left: 1px solid rgba(191, 191, 191, 0.4);
  }
  @media (min-width: 1000px) {
    #timeline .demo-card-wrapper::after {
      border-left: 1px solid #bdbdbd;
    }
  }
  #timeline .demo-card {
    position: relative;
    display: block;
    margin: 10px auto 80px;
    max-width: 94%;
    z-index: 2;
  }
  @media (min-width: 480px) {
    #timeline .demo-card {
      max-width: 60%;
      box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
    }
  }
  @media (min-width: 720px) {
    #timeline .demo-card {
      max-width: 40%;
    }
  }
  @media (min-width: 1000px) {
    #timeline .demo-card {
      max-width: 450px;
      height: 400px;
      margin: 90px;
      margin-top: 45px;
      margin-bottom: 45px;
    }
    #timeline .demo-card:nth-child(odd) {
      margin-right: 45px;
    }
    #timeline .demo-card:nth-child(odd) .head::after {
      border-left-width: 15px;
      border-left-style: solid;
      left: 100%;
    }
    #timeline .demo-card:nth-child(odd) .head::before {
      left: 491.5px;
    }
    #timeline .demo-card:nth-child(even) {
      margin-left: 45px;
    }
    #timeline .demo-card:nth-child(even) .head::after {
      border-right-width: 15px;
      border-right-style: solid;
      right: 100%;
    }
    #timeline .demo-card:nth-child(even) .head::before {
      right: 489.5px;
    }
    #timeline .demo-card:nth-child(2) {
      margin-top: 180px;
    }
  }
  #timeline .demo-card .head {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: 400;
  }
  #timeline .demo-card .head .number-box {
    display: inline;
    float: left;
    margin: 15px;
    padding: 10px;
    font-size: 35px;
    line-height: 35px;
    font-weight: 600;
    background: rgba(0, 0, 0, 0.17);
  }
  #timeline .demo-card .head h2 {
    text-transform: uppercase;
    font-size: 1.3rem;
    font-weight: inherit;
    letter-spacing: 2px;
    margin: 0;
    padding-bottom: 6px;
    line-height: 1rem;
  }
  @media (min-width: 480px) {
    #timeline .demo-card .head h2 {
      font-size: 165%;
      line-height: 1.2rem;
    }
  }
  #timeline .demo-card .head h2 span {
    display: block;
    font-size: 0.6rem;
    margin: 0;
  }
  @media (min-width: 480px) {
    #timeline .demo-card .head h2 span {
      font-size: 0.8rem;
    }
  }
  #timeline .demo-card .body {
    background: #fff;
    border: 1px solid rgba(191, 191, 191, 0.4);
    border-top: 0;
    padding: 15px;
  }
  @media (min-width: 1000px) {
    #timeline .demo-card .body {
      height: 315px;
    }
  }
  #timeline .demo-card .body p {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 15px;
    color: black;
  }
  #timeline .demo-card .body img {
    display: block;
    width: 100%;
    height: 225px;
  }
  #timeline .demo-card--step1 {
    background-color: #46b8e9;
  }
  #timeline .demo-card--step1 .head::after {
    border-color: #46b8e9;
  }
  #timeline .demo-card--step2 {
    background-color: #3ee9d1;
  }
  #timeline .demo-card--step2 .head::after {
    border-color: #3ee9d1;
  }
  #timeline .demo-card--step3 {
    background-color: #ce43eb;
  }
  #timeline .demo-card--step3 .head::after {
    border-color: #ce43eb;
  }
  #timeline .demo-card--step4 {
    background-color: #4d92eb;
  }
  #timeline .demo-card--step4 .head::after {
    border-color: #4d92eb;
  }
  #timeline .demo-card--step5 {
    background-color: #46b8e9;
  }
  #timeline .demo-card--step5 .head::after {
    border-color: #46b8e9;
  }
  #timeline .demo-card--step6 .head::after {
    border-color: #46b8e9;
  }

  #timeline .demo-card--step6 {
    background-color: #ce43eb;
  }
  
  #timeline .demo-card--step6 .head::after {
    border-color: #ce43eb;
  }





.FlagInHand{
  display: flex;
  align-items: center;
  max-width: 80vw;
  margin: 1em auto;
  color: #ffffff;
}
.FlagInHand:before, .FlagInHand:after {
  content: "";
  flex: 1 0 1em;
  margin: 0 0.3125em;
  border-bottom: 3px solid #ffffff;
  border-radius: 40%;
}









  .center-wrap {
    margin: auto;
    clear: both;
    text-align: center;
  }
  .title-container {
    background-color: #2f2f42;
    display: inline-block;
    font-family: "Quicksand";
    text-shadow: 0px 1px rgba(0, 0, 0, 0.3);
    font-size: 28px;
    height: 60px;
    line-height: 60px;
    margin-top: 20px;
    margin-bottom: 5%;
  }
  .title {
    min-width: 150px;
    max-width: 750px;
    overflow: hidden;
    white-space: nowrap;
    float: left;
    margin-left: -75px;
    margin-right: -75px;
    -webkit-animation: title-width .8s ease;
    -moz-animation: title-width .8s ease;
    -o-animation: title-width .8s ease;
    animation: title-width .8s ease;
  }
  .title a {
    color: white;
    margin-left: 10px;
    margin-right: 10px;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .title a:hover {
    color: rgb(212, 212, 212);
    margin-left: 10px;
    margin-right: 10px;
    -o-transition: .3s;
    -ms-transition: .3s;
    -moz-transition: .3s;
    -webkit-transition: .3s;
    transition: .3s;
  }
  @-webkit-keyframes title-width {
    0% { 
      margin-left: 100px;
      margin-right: 100px;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
    50% {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
  }
  @-moz-keyframes title-width {
    0% { 
      margin-left: 100px;
      margin-right: 100px;
      -moz-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
    50% {
      -moz-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
  }
  @-o-keyframes title-width {
    0% { 
      margin-left: 100px;
      margin-right: 100px;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
    50% {
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
  }
  @keyframes title-width {
    0% { 
      margin-left: 100px;
      margin-right: 100px;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
    50% {
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
      filter: alpha(opacity=0);
      -moz-opacity: 0;
      -khtml-opacity: 0;
      opacity: 0;
      color: rgba(255,255,255,0.0);
    }
  }
  .backflag-left, .backflag-right {
    background: #232332;
    width: 91px;
    height: 56px;
    position: relative;
    margin-top: 18px;
    z-index: -100;
    -webkit-animation: flag-back 1s ease;
    -moz-animation: flag-back 1s ease;
    -o-animation: flag-back 1s ease;
    animation: flag-back 1s ease;
  }
  .backflag-left {
    -webkit-transform: rotate(20deg) skew(20deg);
    -ms-transform: rotate(20deg) skew(20deg);
    transform: rotate(20deg) skew(20deg);
    float: left;
  }
  .backflag-right {
    -webkit-transform: rotate(-20deg) skew(-20deg);
    -ms-transform: rotate(-20deg) skew(-20deg);
    transform: rotate(-20deg) skew(-20deg);
    float: right;
  }
  @-webkit-keyframes flag-back {
    0% { 
      -webkit-transform: rotate(0deg) skew(0deg);
      -ms-transform: rotate(0deg) skew(0deg);
      transform: rotate(0deg) skew(0deg);
      margin-top: 0px;
    }
  }
  @-moz-keyframes flag-back {
    0% { 
      -webkit-transform: rotate(0deg) skew(0deg);
      -ms-transform: rotate(0deg) skew(0deg);
      transform: rotate(0deg) skew(0deg);
      margin-top: 0px;
    }
  }
  @-o-keyframes flag-back {
    0% { 
      -webkit-transform: rotate(0deg) skew(0deg);
      -ms-transform: rotate(0deg) skew(0deg);
      transform: rotate(0deg) skew(0deg);
      margin-top: 0px;
    }
  }
  @keyframes flag-back {
    0% { 
      -webkit-transform: rotate(0deg) skew(0deg);
      -ms-transform: rotate(0deg) skew(0deg);
      transform: rotate(0deg) skew(0deg);
      margin-top: 0px;
    }
  }
  .ribbon-left, .ribbon-right {
    background: #29293a;
    margin-top: 31px;
    position: absolute;
    height: 60px;
    width: 120px;
    z-index: -300;
  }
  .ribbon-left {
    float: left;
    margin-left: -15px;
    text-align: center;
    -webkit-animation: flag-end-left 1s ease;
    -moz-animation: flag-end-left 1s ease;
    -o-animation: flag-end-left 1s ease;
    animation: flag-end-left 1s ease;
  }
  .ribbon-right {
    display: inline;
    margin-left: -15px;
    -webkit-animation: flag-end-right 1s ease;
    -moz-animation: flag-end-right 1s ease;
    -o-animation: flag-end-right 1s ease;
    animation: flag-end-right 1s ease;
  }
  @-webkit-keyframes flag-end-right {
    0% { 
      margin-top: 0;
      width: 25px;
    }
  }
  @-moz-keyframes flag-end-right {
    0% { 
      margin-top: 0;
      width: 25px;
    }
  }
  @-o-keyframes flag-end-right {
    0% { 
      margin-top: 0;
      width: 25px;
    }
  }
  @keyframes flag-end-right {
    0% { 
      margin-top: 0;
      width: 25px;
    }
  }
  @-webkit-keyframes flag-end-left {
    0% { 
      margin-top: 0;
      width: 25px;
      margin-left: 63px;
    }
  }
  @-moz-keyframes flag-end-left {
    0% { 
      margin-top: 0;
      width: 25px;
      margin-left: 63px;
    }
  }
  @-o-keyframes flag-end-left {
    0% { 
      margin-top: 0;
      width: 25px;
      margin-left: 63px;
    }
  }
  @keyframes flag-end-left {
    0% { 
      margin-top: 0;
      width: 25px;
      margin-left: 63px;
    }
  }
  .ribbon-left:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 30px solid #29293a;
    border-left: 50px solid transparent;
    float: left;
    margin-left: -50px;
    margin-top: 30px;
  }
  .ribbon-left:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 30px solid #29293a;
    border-left: 50px solid transparent;
    float: left;
    margin-left: -50px;
    margin-top: 0;
  }
  .ribbon-right:before {
    content: '';
    width: 0;
    height: 0;
    border-bottom: 30px solid #29293a;
    border-right: 50px solid transparent;
    float: right;
    margin-right: -50px;
    margin-top: 30px;
  }
  .ribbon-right:after {
    content: '';
    width: 0;
    height: 0;
    border-top: 30px solid #29293a;
    border-right: 50px solid transparent;
    float: right;
    margin-right: -50px;
    margin-top: 0;
  }
  @media all and (max-width: 1340px) {
    .center-wrap {
      width: 100%;
    }
  }
  @media all and (max-width: 850px) {
    .title-container {
      font-size:3.9vw;
      margin-bottom: 10%;
    }
  }


  


  
.email{
    background: #000000;
    padding: 0.2rem 0.7rem;
    border-radius: 30px;
    cursor: pointer;
    font-weight: 900;
    color: white;
}
.edu-care-social-media {
    margin : 3% 0%;
}
.edu-care-social-media i{
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0px 8px;

}
.eduCareContainer{
    margin:  3% 1%;
}
.ceo-title{
    font-size: 1.8rem;
    font-weight: 900;
}
.jij{
    display: flex;
    justify-content: space-around
}
.responsive {
    width: 100%;
    align-items: center;
    max-height: 26rem;
    border-radius: 2%;
  }
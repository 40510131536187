body {
  background-color: #080808;
  color: #fff;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500;
  font-style: normal;
}



.name {
  color: rgb(233 210 255);
  font-family: "Bungee Inline", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.forText {
  text-align: justify;
  font-family: "Outfit", sans-serif !important;
  font-weight: 400;
  font-style: normal;
}

.seeMoreBtn {
  box-shadow: none !important;
  margin: 3px 15px !important;
}

.typewriter {
  margin-bottom: 1rem;
  font-family: "Bungee Inline", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.Typewriter {
  margin-bottom: 0.7rem;

}

.forWelcome {
  text-align: end;
  color: palevioletred;
}

.homeFollow {
  margin-bottom: 2rem;

}

.homeFollow strong {
  font-size: 1.3rem !important;
}

.HomePageImg {
  width: auto;
  height: auto;
  max-height: 500px;
}

.name span {
  color: #ff004f;
}



a i {
  font-size: 1.8rem;
  margin: 2px 8px;
  cursor: pointer;
}

.Typewriter__wrapper {
  font-size: 0.95rem !important;
}

.bottomImg {
  display: block;
  margin-left: auto;
  width: 112px;
}

@media only screen and (max-width: 600px) {
  .HomePageImg {
    width: 315px;
    height: auto;
    max-height: 330px;

  }

  .bottomImg {
    display: block;
    margin-left: auto;
    width: 80px;
    height: auto;

  }
  .EidImag {
    margin-top: 42% !important;
  }

  /* #background {
        min-width: 100%;
        min-height: 100%;
        width: auto;
        height: 500px;
        z-index: -1;
        background: url('https://images.unsplash.com/photo-1505410603994-c3ac6269711f?ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80') no-repeat;
        background-size: contain;
        background-color: rgba(0, 35, 82, 0.7);
        top: 0;
        left: 0;
        z-index:999;
        color: white;
    } */
}




body {
  overflow-x: hidden;
}

.showcase {
  width: 100%;
  height: 700px;
  position: relative;
  color: white;
  text-align: center;
}

.showcase img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99
}

.showcase .overlay {
  width: 100%;
  height: 700px;
  background-color: rgba(0, 35, 82, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999
}

.showcase h2 {
  font-size: 3em;
}

.showcase p {
  margin-top: 8px;
  font-size: 1.2em;
}

.responsive2 {
  width: 55%;
  align-items: center;
  max-height: 27rem;
  border-radius: 2%;
  background-color: rgb(255, 255, 255);
  margin-bottom: 1rem;
}


.EidImag {
  margin-top: 10%;
}
#cards
{
  width: 800px;
  overflow-x: hidden;
  display: flex;
  align-items: center;
  justify-content: left;
  gap: 200px;
  scroll-behavior: smooth;
}

.section
{
  list-style: none;
  padding: 0;
  display: inline-flex;
  gap: 20px;
  margin: 5px;
}
.card
{
  position: relative;
  border-radius: 15px;
  height: 400px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.inner
{
  position: absolute;
  background: linear-gradient(white, black);
  border-radius: 15px;
  height: calc(100% - 2px);
  width: calc(100% - 2px);
  overflow: hidden;
}
.img
{
  opacity: 0.7;
  position: absolute;
  user-select: none;
  height: 100%;
  left: -10px;
  right: 0;
  transition: opacity 200ms;
}
.card-bg
{
  position: absolute;
  background-image: linear-gradient(transparent 15%, var(--out-color) 40%, var(--in-color), var(--out-color) 60%, transparent 85%);
  background-repeat: no-repeat;
  background-position: center;
  resize: none;
  border-radius: 15px;
  height: 100%;
  width: 100vh;
  transition: transform 500ms;
  z-index: 0;
}

#buttons
{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.button
{
  background-color: rgb(100, 150, 255);
  border-radius: 40px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  user-select: none;
  text-decoration: none;
}

.card:hover .card-bg
{
  transform: rotate(180deg);
}

.card:hover .img
{
  opacity: 1;
}

.responsive3{
    max-width: 330px;
    max-height: 330px;
}
/* .owl-item{
    width: 350px !important;
} */
.gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    grid-gap: 10px;
}

.gallery__item {
    overflow: hidden;
}

.gallery__image {
    width: 100%;
    height: auto;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    cursor: pointer;
}

.gallery__item:hover {
    opacity: 0.8;
}

.gallery__item:hover .gallery__image {
    transform: scale(1.3);
}

/* Lightbox styles */
.lightbox {
    position: fixed;
    top: 100px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.lightbox__image {
    max-width: 90%;
    max-height: 90%;
}

/* Close lightbox */

.lightbox__close-button {
    position: absolute;
    top: 10px;
    right: 35px;
    color: #ff0000;
    font-size: 30px;
    padding: 0px 5px;
    cursor: pointer;
}

.lightbox__close-button:hover {
    opacity: 0.5;
}

.lightbox__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    z-index: -1;
    cursor: pointer;
}
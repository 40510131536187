@media screen and (max-width: 768px) {
    html {
        font-size:15px
    }
}

@media screen and (max-width: 365px) {
    html {
        font-size:14px
    }
}

.card-Container{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.card{
  width: 25rem;
  height: auto;
  background: #272727;
  border-radius: 1.5rem;
  padding: 1.5rem;
  overflow: hidden;
  position: relative;
  color: white !important;
}

.card-img-holder{
  width: 100%;
  height: auto;
  position: relative;
}

.card-img-holder img{
  width: 100%;
  height: auto;
  max-height: 15rem;
  object-fit: cover;
  border-radius: 1.5rem;
}

.blog-title{
  font-size: 1.5rem;
}

.description{
  padding: 1rem 0;
  font-size: 1rem;
  border-bottom: 2px solid;
}

.blog-time{
  font-size: .8rem;
}

.options{
  display: flex;
  align-items: center;
  justify-content: space-between !important;
  font-size: 1.5rem;
}

.options span{
  font-weight: 600;
}

.btn{
  font-size: 1rem;
  padding: .5rem 0.5rem;
  border-radius: .5rem;
  font-weight: 400;
  background: #EEF7FE;
  color: #22215B;
  cursor: pointer;
}
.navbar {
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 20;

}

.nameByImg {
  width: 103px;
  height: 44px;
  border-radius: 10px;
}

.nav-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 80px;
  max-width: 1500px;
  position: relative;
}

.nav-logo span {
  font-size: 2rem !important;
}

.nav-logo {
  color: #f5b921;
  align-items: center;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-items: center;
}

.nav-logo .icon {
  display: inline-block;
  width: 3rem;
  height: 3rem;
  margin-left: 16px;
}

.nav-menu {
  display: flex;
  list-style: none;
  text-align: center;
}

.nav-links {
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  border-bottom: 3px solid transparent;
  font-size: 1.2rem !important;
}

/* .fa-code {
  margin-left: 1rem;
} */

.nav-item {
  line-height: 40px;
  margin-right: 1rem;
}

.nav-item:after {
  content: "";
  display: block;
  height: 3px;
  width: 0;
  background: transparent;
  transition: width 0.7s ease, background-color 0.5s ease;
}

.nav-item:hover:after {
  width: 100%;
  background: #ffdd40;
}

.nav-item .active {
  color: #dee517;
  border: 1px solid #ffdd40;
}

.nav-icon {
  display: none;
  color: #f5b921;
}

.nav-icon .icon {
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
}

.navbar ul {
  margin-bottom: 0px !important;
}

.image-name {
  width: 32% !important;
}

.navbar {
  margin-top: -125px !important;
}


@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-top: 1pxsolid #fff;
    position: absolute;
    top: 80px;
    left: -110%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #000000;
    left: 0px;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-item .active {
    color: #40ff53;
    border: none;
  }

  .nav-links {
    padding: 1.5rem;
    width: 100%;
    display: table;
  }

  .nav-logo .icon {
    width: 2rem;
    height: 2rem;
  }

  .nav-icon {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-100%, -50%);
    width: 2rem;
    height: 2rem;
    cursor: pointer;
    color: #ffdd40;
  }

  .image-name {
    width: -webkit-fill-available !important;
  }

  .nav-container {
    justify-content: center;
    margin-right: 20%;
  }

  .navbar {
    margin-top: -100px !important;
  }
}